import { persistState } from '@au/core/lib/utils/stateDelegators';
import { fromJS } from "immutable";

export default {
  SET_SETTING: (state, action) => {
    state = persistState(state)
      .call('setIn', ['settings', action.name], action.value)
      .state;
    return state;
  },
  SET_FIELDS_SELECTION: (state, action) => {
    const { fieldsSelection, pageType, serviceAlias, entityAlias } = action;
    state = persistState(state).call(
      "setIn",
      ["fieldsSelections", pageType, serviceAlias, entityAlias],
      fromJS(fieldsSelection)
    ).state;
    return state;
  },
};
