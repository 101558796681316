import React from "react";
import * as T from "prop-types";
import cn from "classnames";
import IPT from "react-immutable-proptypes";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import AutoIntl from '@au/core/lib/components/elements/AutoIntl';
import AuComponent from "@au/core/lib/components/elements/AuComponent";

import styles from "../css/components/field_selector.module.scss";

const AVAILABLE_DROPPABLE = "availableDroppable";
const SELECTED_DROPPABLE = "selectedDroppable";

class Field extends React.Component {
  static Type = {
    ADD: "add",
    REMOVE: "remove",
  };

  static propTypes = {
    type: T.oneOf([this.Type.ADD, this.Type.REMOVE]).isRequired,
    property: T.string,
    label: T.string,
    index: T.number,
    onSelected: T.func.isRequired,
  };

  render() {
    const { property, label, type, onSelected, index } = this.props;

    return (
      <Draggable key={property} draggableId={property} index={index}>
        {(provided) => {
          const style = { ...provided.draggableProps.style };
          return (
            <div
              ref={provided.innerRef}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
              style={style}
              className={styles.field_item}
            >
              <span className={styles.draggable}></span>
              <AutoIntl
                className={styles.field_item_text}
                displayString={label || property}
              />
              <span
                className={styles[type]}
                onClick={() => onSelected(index)}
              ></span>
            </div>
          );
        }}
      </Draggable>
    );
  }
}

class AvailableFields extends React.Component {
  static propTypes = {
    data: T.arrayOf(
      T.shape({
        property: T.string,
        display: T.bool,
        label: T.string,
        selectable: T.bool,
      })
    ),
    onSelected: T.func.isRequired,
    pointerStatus: T.string,
    draggingStatus: T.bool,
  };

  constructor(props) {
    super(props);
    this.state = {
      expanded: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.pointerStatus !== this.props.pointerStatus && !this.props.draggingStatus) {
      this.setState({ expanded: false });
    }
  }

  getSortedData() {
    this.props.data.sort((a, b) => {
      const getText = (obj) => obj.label || obj.property;
      if (getText(a) < getText(b)) return -1;
      if (getText(a) > getText(b)) return 1;
      return 0;
    });
    return this.props.data;
  }

  renderFields() {
    const { data, onSelected } = this.props;
    if (data.length === 0) return;
    return (
      <div className={styles.available_fields}>
        {this.getSortedData().map(({ property, label }, index) => (
          <Field
            property={property}
            label={label}
            type={Field.Type.ADD}
            key={property}
            index={index}
            onSelected={onSelected}
          />
        ))}
      </div>
    );
  }

  toggleContent = this.toggleContent.bind(this);
  toggleContent() {
    this.props.data.length !== 0 && this.setState((prevState) => ({
      expanded: !prevState.expanded,
    }));
  }

  render() {
    const { expanded } = this.state;
    const { data } = this.props;

    const classes = cn(styles.collapsible, {
      [styles.expanded]: expanded
    });
    const iconClasses = cn(styles.collapsible_icon, {
      [styles.expanded_icon]: expanded,
      [styles.disabled_icon]: data.length == 0
    });

    return (
      <Droppable droppableId={AVAILABLE_DROPPABLE}>
        {(provided) => (
          <div
            ref={provided.innerRef}
            {...provided.droppableProps}
            className={classes}
          >
            <div
              className={cn(styles.collapsible_header, {[styles.disabled]: data.length == 0})}
              onClick={this.toggleContent}
            >
              <div className={styles.collapsible_number}>{data.length}</div>
              <AutoIntl
                className={cn(styles.collapsible_title, {[styles.disabled]: data.length == 0})}
                displayId="au.entity.fieldSelector.availableFields"
              />
              <span className={iconClasses}></span>
            </div>
            {this.renderFields()}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    );
  }
}

class SelectedFields extends React.Component {
  static propTypes = {
    data: T.arrayOf(
      T.shape({
        property: T.string,
        display: T.bool,
        label: T.string,
        selectable: T.bool,
      })
    ),
    onSelected: T.func.isRequired,
  };

  render() {
    const { data, onSelected } = this.props;
    if (data.length === 0) return;
    return (
      <Droppable droppableId={SELECTED_DROPPABLE}>
        {(provided) => (
          <div
            ref={provided.innerRef}
            {...provided.droppableProps}
            className={styles.selected_fields}
          >
            {data.map(({ property, label }, index) => (
              <Field
                property={property}
                label={label}
                type={Field.Type.REMOVE}
                key={property}
                index={index}
                onSelected={onSelected}
              />
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    );
  }
}

export default class FieldsSelector extends AuComponent {
  static propTypes = {
    fieldsSelection: T.oneOfType([IPT.list, IPT.map]),
  };

  static POINTER_STATUS = {
    ENTER: "enter",
    LEAVE: "leave",
  };

  constructor(props) {
    super(props);

    const fieldsSelection = props.fieldsSelection?.toJS();
    this.state = {
      active: false,
      dragging: false,
      pointerStatus: FieldsSelector.POINTER_STATUS.LEAVE,
      availableFields: this.filterByDisplay(fieldsSelection, false),
      selectedFields: this.sortByOrder(
        this.filterByDisplay(fieldsSelection, true)
      ),
    };
  }

  componentDidUpdate(prevProps) {
    if (
      !prevProps.fieldsSelection ||
      (prevProps.fieldsSelection &&
        !prevProps.fieldsSelection.equals(this.props.fieldsSelection))
    ) {
      const fieldsSelection = this.props.fieldsSelection?.toJS();
      this.setState({
        availableFields: this.filterByDisplay(fieldsSelection, false),
        selectedFields: this.sortByOrder(
          this.filterByDisplay(fieldsSelection, true)
        ),
      });
    }
  }

  filterByDisplay(array, display) {
    if (!array) return [];
    return array
      .filter((field) => field.selectable)
      .filter((field) => field.display === display)
      .filter((field) => field.property !== "actions");
  }

  sortByOrder = this.sortByOrder.bind(this);
  sortByOrder(arr) {
    arr.sort((a, b) => (a.order < b.order ? -1 : a.order > b.order ? 1 : 0));
    return arr;
  }

  clickHandler = this.clickHandler.bind(this);
  clickHandler() {
    this.setState((prevState) => ({ active: !prevState.active }));
  }

  updateFieldsSelectionStore() {
    const { actions, pageType, serviceAlias, entityAlias } = this.props;
    const fieldsSelection = this.props.fieldsSelection.toJS();

    this.state.availableFields.forEach(({ property }) => {
      this.updateDisplayAndOrder(fieldsSelection, property, false, -1);
    });

    this.state.selectedFields.forEach(({ property }, index) => {
      this.updateDisplayAndOrder(fieldsSelection, property, true, index + 1); // shift by 1 index to avoid conflict with ID field
    });

    actions.setFieldsSelection({
      fieldsSelection,
      pageType,
      serviceAlias,
      entityAlias,
    });
  }

  reorderFields = this.reorderFields.bind(this);
  reorderFields(sourceIndex, destinationIndex) {
    const selectedFields = [...this.state.selectedFields];
    const [draggingField] = selectedFields.splice(sourceIndex, 1);
    selectedFields.splice(destinationIndex, 0, draggingField);
    this.setState({ selectedFields: selectedFields }, () => {
      this.updateFieldsSelectionStore();
    });
  }

  addField = this.addField.bind(this);
  addField(sourceIndex, destinationIndex = this.state.selectedFields.length) {
    const availableFields = [...this.state.availableFields];
    const [addingField] = availableFields.splice(sourceIndex, 1);
    const selectedFields = [...this.state.selectedFields];
    selectedFields.splice(destinationIndex, 0, addingField);
    this.setState({ selectedFields, availableFields }, () => {
      this.updateFieldsSelectionStore();
    });
  }

  removeField = this.removeField.bind(this);
  removeField(
    sourceIndex,
    destinationIndex = this.state.availableFields.length
  ) {
    const selectedFields = [...this.state.selectedFields];
    const [removedField] = selectedFields.splice(sourceIndex, 1);
    const availableFields = [...this.state.availableFields];
    availableFields.splice(destinationIndex, 0, removedField);
    this.setState({ selectedFields, availableFields }, () => {
      this.updateFieldsSelectionStore();
    });
  }

  updateDisplayAndOrder = this.updateDisplayAndOrder.bind(this);
  updateDisplayAndOrder(array, property, display, order) {
    const found = array.find((field) => field.property === property);
    if (found) {
      found.display = display;
      found.order = order;
    }
  }

  handleOnPointerEnter = this.handleOnPointerEnter.bind(this);
  handleOnPointerEnter() {
    this.setState({ pointerStatus: FieldsSelector.POINTER_STATUS.ENTER });
  }

  handleOnPointerLeave = this.handleOnPointerLeave.bind(this);
  handleOnPointerLeave() {
    this.setState({ pointerStatus: FieldsSelector.POINTER_STATUS.LEAVE });
  }

  onDragEnd = this.onDragEnd.bind(this);
  onDragEnd(result) {
    const { source, destination } = result;
    const dropOutsideDroppable = () => !destination;
    const remainInAvailableSection = () =>
      destination.droppableId === AVAILABLE_DROPPABLE &&
      source.droppableId === AVAILABLE_DROPPABLE;
    const remainInSelectedSection = () =>
      destination.droppableId === SELECTED_DROPPABLE &&
      source.droppableId === SELECTED_DROPPABLE;
    const hasNoChange = () =>
      destination.droppableId === source.droppableId &&
      destination.index === source.index;

    this.setState({ dragging: false });

    if (dropOutsideDroppable() || hasNoChange() || remainInAvailableSection())
      return;

    if (remainInSelectedSection()) {
      return this.reorderFields(source.index, destination.index);
    }

    if (destination.droppableId === AVAILABLE_DROPPABLE) {
      return this.removeField(source.index, destination.index);
    }

    if (destination.droppableId === SELECTED_DROPPABLE) {
      return this.addField(source.index, destination.index);
    }
  }

  onDragStart = this.onDragStart.bind(this);
  onDragStart() {
    this.setState({ dragging: true });
  }

  render() {
    const { active, dragging, pointerStatus, availableFields, selectedFields } =
      this.state;
    const classes = cn(styles.container, {
      [styles.active]: active,
      [styles.dragging]: dragging,
    });

    return (
      <DragDropContext
        onDragEnd={this.onDragEnd}
        onDragStart={this.onDragStart}
      >
        <div
          className={classes}
          onPointerEnter={this.handleOnPointerEnter}
          onPointerLeave={this.handleOnPointerLeave}
        >
          <div className={styles.action_menu} onClick={this.clickHandler}>
            <span className={styles.action_menu_icon}></span>
            <span className={styles.action_menu_caret}></span>
            <AutoIntl
              className={styles.action_menu_label}
              displayId="au.entity.fields"
            />
          </div>
          <div className={styles.controller}>
            <AvailableFields
              data={availableFields}
              onSelected={this.addField}
              pointerStatus={pointerStatus}
              draggingStatus={dragging}
            />
            <SelectedFields
              data={selectedFields}
              onSelected={this.removeField}
            />
          </div>
        </div>
      </DragDropContext>
    );
  }
}