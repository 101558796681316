import React from 'react';
import cn from 'classnames';
import * as T from 'prop-types';
import IPT from 'react-immutable-proptypes';
import { Link } from 'react-router-dom';
import AuComponent from '@au/core/lib/components/elements/AuComponent';
import CommonGlobalNavigation from '@au/core/lib/components/objects/GlobalNavigation';
import { appendQueryParams } from '@au/core/lib/utils/url';
import { createResponseAlertMessage } from '@au/core/lib/components/objects/AlertMessage';

import shared from '../shared';
import serviceDefs from '../services/serviceDefs';
import {
  SERVICES_PATH,
  TOPOLOGY_PATH,
  customCredsQueryParams,
  COMPONENT_GALLERY_PATH,
  COMPONENT_GALLERY_PAGES,
  SERVICE_NAMES,
  PROD_LINK,
  DEV_LINK,
  PROD_ENV,
  DEV_ENV,
  STAGING_ENV
} from '../constants';
import apiFactory from '../utils/api';
import { formatMessage } from '../utils/reactIntl';
import { setPartition } from '../utils/linkHelper';
import { shouldHideContent } from '../utils/entity';
import MobilePageHeader from './MobilePageHeader';
import Footer from './Footer';

import styles from '../css/components/global_navigation.module.scss';

export const custOnClick = Object.fromEntries(Object.entries(customCredsQueryParams).map(([tab, pq]) =>
  [ tab, () => window.location.href = appendQueryParams(window.location.href, [[pq]]) ]
));

function filterDisplayForUser(serviceOrEntity) {
  // default is true
  // do not filter if using custom credentials and only filter if displayForUser equals `false`
  // "user" means public user obtained via regular code flow, not a client or service account
  // it is possible to use a token for a user, but we will assume tokens are for clients or service accounts
  return shared.usingCustomCreds || serviceOrEntity.displayForUser !== false;
}

export default class GlobalNavigation extends AuComponent {
  static propTypes = {
    pageTitle: T.string,
    screenWidth: T.string,
    notifications: T.object,
    accountId: T.string,
    accountName: T.string,
    partition: T.string,
    accounts: IPT.map,
    showStatusPageLink: T.bool,
    actions: T.shape({
      setAccountId: T.func.isRequired
    }).isRequired
  };

  static defaultProps = {
    notifications: {}
  };

  constructor(props) {
    super(props);

    const showOriginalNavBar = window.localStorage.getItem('showV1NavBar');

    if (showOriginalNavBar == null) {
      this.state = {
        showV1NavBar: true,
        showV2NavBar: false,
        toggleV1NavBar: true,
        toggleV2NavBar: false
      };
    } else if (showOriginalNavBar !== null) {
      this.state = {
        showV1NavBar: showOriginalNavBar == 'true' ? true : false,
        showV2NavBar: showOriginalNavBar == 'true' ? false : true,
        toggleV1NavBar: showOriginalNavBar == 'true' ? true : false,
        toggleV2NavBar: showOriginalNavBar == 'true' ? false : true
      };
    }
  }

  componentDidMount() {
    const { actions } = this.props;
    const endpoint = apiFactory(SERVICE_NAMES.ACCOUNTS, 'accounts', actions);
    endpoint.list().catch(createResponseAlertMessage);
  }

  getPartitions() {
    return shared.config.partitions ? Object.entries(shared.config.partitions).map(([value, partition]) =>
      ({ displayString: partition.label, value })
    ) : [];
  }

  get partition() {
    const { partition } = this.props;
    return shared.config.partitions ? shared.config.partitions[partition].label : '';
  }

  renderHeader() {
    const { screenWidth, schema, pageTitle } = this.props;

    if (['desktop', 'tabletLandscape'].includes(screenWidth) || !pageTitle) {
      return false;
    }

    return <MobilePageHeader schema={schema} pageTitle={pageTitle} />;
  }

  getNavigationGroup(serviceDef, entityDef) {
    if (entityDef.navGroup) return entityDef.navGroup;
    if (serviceDef.navGroup) return serviceDef.navGroup;
    else return "manage";
  }

  isDisplayFalse({ display }) {
    const env = this.getEnvironment();
    return display !== undefined && Object.keys(display).length > 1
      ? display[env] === false
      : display === false;
  }

  getEnvironment() {
    if (window.location.origin === PROD_LINK) return PROD_ENV;
    if (window.location.origin === DEV_LINK) return DEV_ENV;
    return STAGING_ENV;
  }

  generateEntityGroups() {
    const { accountId } = this.props;
    const entityGroups = [];

    Object.entries(serviceDefs)
      .filter(([a]) => serviceDefs[a].display !== false)
      .filter(([a]) => filterDisplayForUser(serviceDefs[a]))
      .filter(([a]) => !shouldHideContent(serviceDefs[a]))
      .forEach(([serviceAlias, serviceDef]) => {
        const { entities, accountAware } = serviceDef;
        if (serviceDef) {
          for (let [entityAlias, entityDef] of Object.entries(entities)) {
            const { type, labelId, landingPage } = entityDef;
            if (this.isDisplayFalse(entityDef) || accountAware && !accountId) {
              continue;
            }
            if (!filterDisplayForUser(entityDef) || shouldHideContent(entityDef)) {
              continue;
            }
            const displayId = labelId ?? `au.entity.title.${type}`;
            const destination = `${SERVICES_PATH}/${serviceAlias}/${entityAlias}/${landingPage || "list"
              }`;
            const navItem = {
              destination,
              labelId: displayId,
              sortingName: formatMessage({ id: displayId })
            };
            const navGroup = this.getNavigationGroup(serviceDef, entityDef);
            if (entityGroups[navGroup]) {
              entityGroups[navGroup].push(navItem);
            } else {
              entityGroups[navGroup] = [navItem];
            }
          }
        }
      });

    entityGroups.data?.splice(5, 0,
      {
        labelId: 'au.section.title.topologyOverview',
        destination: TOPOLOGY_PATH,
        isVisible: accountId
      }
    );

    return entityGroups;
  }

  generateManageNavLinks() {
    const { accountId } = this.props;

    const manageEntities = [];
    Object.entries(serviceDefs)
      .filter(([a]) => serviceDefs[a].display !== false)
      .filter(([a]) => filterDisplayForUser(serviceDefs[a]))
      .filter(([a]) => !shouldHideContent(serviceDefs[a]))
      .forEach(([serviceAlias, serviceDef]) => {
        if (serviceDef) {
          for (let [entityAlias, entityDef] of Object.entries(serviceDef.entities)) {
            if (entityDef.display === false || serviceDef.accountAware && !accountId) {
              continue;
            }
            if (!filterDisplayForUser(entityDef) || shouldHideContent(entityDef)) {
              continue;
            }
            let displayId = `au.entity.title.${entityDef.type}`;
            manageEntities.push({
              destination: `${SERVICES_PATH}/${serviceAlias}/${entityAlias}/${entityDef.landingPage || 'list'}`,
              labelId: displayId,
              sortingName: formatMessage({ id: displayId })
            });
          }
        }
      });

    // sort entities alphabetically by labelId
    manageEntities.sort((a, b) =>
      b.sortingName > a.sortingName
        ? -1
        : (a.sortingName > b.sortingName ? 1 : 0)
    );

    return manageEntities;
  }

  sortByProperty(array, property) {
    array.sort((a, b) => {
      if (b[property] > a[property]) return -1;
      if (a[property] > b[property]) return 1;
      return 0;
    });
    return array;
  }

  generateEntityNavLinks() {
    const { accountId } = this.props;
    const navLinksItems = [];
    const entityGroups = this.generateEntityGroups();
    Object.entries(entityGroups).forEach(([section, navLinks]) => {
      const labelId = `au.section.title.${section}`;
      navLinksItems.push({
        labelId,
        isVisible: Boolean(navLinks.length && accountId),
        navLinks: this.sortByProperty(navLinks, "sortingName"),
        sortingName: formatMessage({ id: labelId }),
      });
    });
    return this.sortByProperty(navLinksItems, "sortingName");
  }

  generateNavLinks() {
    const { accountId } = this.props;
    const { showV1NavBar, showV2NavBar } = this.state;
    const isDevEnv = process.env.NODE_ENV === 'development';
    const manageNavLinks = this.generateManageNavLinks();
    const entityNavLinks = this.generateEntityNavLinks();
    let navLinks;

    if (showV1NavBar) {
      navLinks = [
        {
          labelId: 'au.section.title.topology',
          destination: TOPOLOGY_PATH,
          isVisible: Boolean(manageNavLinks.length && accountId)
        },
        {
          labelId: 'au.section.title.manage',
          isVisible: Boolean(manageNavLinks.length && accountId),
          navLinks: manageNavLinks
        },
        {
          labelId: 'au.section.title.component_gallery',
          isVisible: isDevEnv,
          navLinks: Object.values(COMPONENT_GALLERY_PAGES).map(pageAlias => ({
            destination: `${COMPONENT_GALLERY_PATH}/${pageAlias}`,
            labelId: `au.section.title.component_gallery.${pageAlias}`,
          })),
        },
      ];
    }
    if (showV2NavBar) {
      navLinks = [
        ...entityNavLinks,
        {
          labelId: 'au.section.title.component_gallery',
          isVisible: isDevEnv,
          navLinks: Object.values(COMPONENT_GALLERY_PAGES).map(pageAlias => ({
            destination: `${COMPONENT_GALLERY_PATH}/${pageAlias}`,
            labelId: `au.section.title.component_gallery.${pageAlias}`,
          })),
        },
      ];
    }

    const { pathname } = window.location;
    // iterate over primary links to find those containing secondary links
    for (let i = 0, pLinksLen = navLinks.length; i < pLinksLen; i++) {
      const primaryLink = navLinks[i];
      let matchFound = primaryLink.destination && pathname === primaryLink.destination;

      if (!matchFound && 'navLinks' in primaryLink) {
        // iterate over secondary links and find mathing one
        for (let j = 0, sLinksLen = primaryLink.navLinks.length; j < sLinksLen; j++) {
          const secondaryLink = primaryLink.navLinks[j];

          if (secondaryLink.destination === pathname) {
            // all the common links
            primaryLink.selected = true;
            matchFound = true;
            break;
          } else if (pathname.startsWith(SERVICES_PATH)) {
            // entity framework links handling
            const { serviceAlias } = this.props.match.params;
            const entityAlias = pathname.split('/')[pathname.split('/').indexOf(serviceAlias) + 1];
            if (secondaryLink.destination
                && secondaryLink.destination.startsWith(`${SERVICES_PATH}/${serviceAlias}/${entityAlias}`)) {
              primaryLink.selected = true;
              secondaryLink.selected = true;
              matchFound = true;
              break;
            }
          }
        }
      }

      if (matchFound) {
        break;
      }
    }

    return navLinks;
  }

  updateNavBar() {
    window.localStorage.setItem('showV1NavBar', false);
    this.setState(prevState => ({
      showV1NavBar: !prevState.showV1NavBar,
      showV2NavBar: !prevState.showV2NavBar,
      toggleV1NavBar: !prevState.toggleV1NavBar,
      toggleV2NavBar: !prevState.toggleV2NavBar
    }));
  }

  applyChanges() {
    const { toggleV1NavBar, toggleV2NavBar } = this.state;
      this.setState({ showV1NavBar: toggleV1NavBar, showV2NavBar: toggleV2NavBar });
      window.localStorage.setItem('showV1NavBar', toggleV1NavBar);
  }

  render() {
    const { accounts, accountId, children, notifications, accountName, actions } = this.props;
    const { toggleV1NavBar, toggleV2NavBar } = this.state;
    const showMenu = Boolean(this.partition || accountId);

    return (
      <CommonGlobalNavigation
        navLinks={this.generateNavLinks()}
        breadCrumbs={this.renderHeader()}
        logoLink={<Link to="/" />}
        className={cn(styles.nav, {
          [styles.alert]: notifications.servicesDown,
          [styles.warning]: !notifications.servicesDown && notifications.servicesDegraded
        })}
        navLinkClassName={styles.navlink}
        activeNavLinkClassName={styles.selected}
        accountId={accountId}
        accountName={accountName}
        accounts={accounts}
        onUserTokenClick={custOnClick['token']}
        onUserClientClick={custOnClick['client']}
        partition={this.partition}
        partitions={this.getPartitions()}
        confidentialClient={shared.usingCustomCreds}
        setAccountId={actions.setAccountId}
        setPartition={setPartition}
        showMenu={showMenu}
        showMenuToggle={true}
        updateMenuClick={() => this.updateNavBar()}
        applyBtnClick={() => this.applyChanges()}
        handleVersionToggle={
          () => this.setState(prevState => ({
            toggleV1NavBar: !prevState.toggleV1NavBar,
            toggleV2NavBar: !prevState.toggleV2NavBar 
          }))
        }
        showV1NavBar={toggleV1NavBar}
        showV2NavBar={toggleV2NavBar}
      >
        { children }
        <Footer/>
      </CommonGlobalNavigation>
    );
  }
}
