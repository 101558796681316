import { fromJS, Map as imMap } from 'immutable';
import transit from 'transit-immutable-js';

import { SERVICE_NAMES } from './constants';
import serviceDefs from './services/serviceDefs';
import shared from '../src/shared';
import {
  getAllJoinedEntities,
  getEntityResources,
  getFulfilledEntity,
  getValidEntities,
  getValidEntity,
  getAllStatusPageSummary,
  getNotifications
} from './utils/selectors';
import { generateEntityPath, getParentEntityAwarePath } from './utils/entity';
import apiFactory from './utils/api';

const ACCOUNTS_PATH = ['services', SERVICE_NAMES.ACCOUNTS, 'entities', 'accounts'];

export default function propProviderGen(topProps, actions) {
  let propProvider;
  propProvider = {
    App: () => {
      const popout = topProps.get('popout', fromJS({})).toJS();
      const location = topProps.get('location');

      return {
        screenWidth: topProps.get('screenWidth'),
        popout: popout.componentName ? popout : null,
        location,
        actions,
      };
    },
    IntlProvider() {
      return {
        language: topProps.get('locale')
      };
    },
    UnitProvider() {
      return {
        system: topProps.get('systemOfUnits')
      };
    },
    ComponentExporter: () => ({
      language: topProps.get('locale'),
      systemOfUnits: topProps.get('systemOfUnits'),
    }),
    Connect: () => {},
    EntityLookup: (ownProps) => {
      const { serviceAlias, endpoint, entityId } = ownProps;
      const entity = topProps.getIn([...generateEntityPath(endpoint, serviceAlias), entityId]);
      return {
        actions,
        serviceAlias,
        endpoint,
        entityId,
        entity
      };
    },
    GlobalNavigation: () => {
      const statusPageSummary = getAllStatusPageSummary(topProps);
      const statusPageServices = statusPageSummary?.getIn(['components']);
      const filteredStatusPageServices = statusPageServices?.filter(s => s.get('group') === false);
      const notifications = getNotifications(filteredStatusPageServices);

      const partition = topProps.getIn(['settings', 'partition']);
      let accountId = topProps.getIn(['settings', 'partitions', partition, 'accountId']);
      const accounts = topProps.getIn(ACCOUNTS_PATH, imMap());
      let accountName;
      const showStatusPageLink = Boolean(shared.config.partitions[partition].statusPageId);

      if ((!accountId || !accounts.has(accountId)) && accounts.size !== 0) { // no accounts during initialization
        accountId = accounts.first().get('id');
      }
      accountName = accounts.getIn([accountId, 'name']);

      return {
        location: topProps.get('location'),
        pageTitle: topProps.get('pageTitle'),
        schema: topProps.get('pageSchema'),
        screenWidth: topProps.get('screenWidth'),
        open: topProps.getIn(['nav', 'open']),
        partition,
        accountId,
        accountName,
        notifications,
        actions,
        accounts,
        showStatusPageLink
      };
    },
    Entity: (ownProps) => {
      const { serviceAlias, entityDef, parentEntity } = ownProps;
      const { entityAlias, action } = ownProps.match.params;
      const entityId = decodeURIComponent(ownProps.match.params.entityId);
      const parentEntityId = parentEntity?.entityId;
      const entityAttributeDefs = Object.values(entityDef?.attributes || {});
      const joinAttributeDefs = entityAttributeDefs.filter(a => a?.join);
      const resources = getEntityResources(topProps, entityDef);

      let path;
      let searchText;
      let searchTextPath;
      let queryParams;
      let queryParamsPath;
      let entity;
      let entities;

      // if there is a parent entity - build the "path" using parent endpoint data
      if (parentEntityId) {
        let parentEntityPath = getParentEntityAwarePath(parentEntity);
        path = [...parentEntityPath, entityAlias];

        // save search query to specific page/entity/service
        let pathIndexKey = path.filter((_, i) => i % 2).join(' - '); // skip "services" and ids
        searchTextPath = ['search', pathIndexKey];
        queryParamsPath = ['query', pathIndexKey];
        // inject parent entity data, remove id from the path before passing to getValidEntities
        ownProps.parentEntity.entity = getValidEntities(topProps, parentEntityPath.slice(0, -1)).get(parentEntityId);
      }
      else {
        path = ['services', serviceAlias, 'entities', entityAlias];
        searchTextPath = ['search', `${serviceAlias} - ${entityAlias}`];
        queryParamsPath = ['query', `${serviceAlias} - ${entityAlias}`];
      }

      if (action === 'list') {
        // Filter records that don't contain [pkFiled] property (assuming
        // it is required). This usually happens when the child entity page
        // was refreshed and then we're trying to navigate to the parent
        // entity page.
        entities   = getAllJoinedEntities(getValidEntities(topProps, path), resources, joinAttributeDefs);
        searchText = topProps.getIn(['userData', ...searchTextPath]);
        queryParams = topProps.getIn(['userData', ...queryParamsPath]);
      }
      else if (action === 'create') {
        entity = imMap();
        for (let [attr, { map, defaultValue }] of Object.entries(entityDef.attributes)) {
          if (defaultValue) {
            entity = entity.set(attr, defaultValue);
          }
          if (map) {
            // `fromJS` is needed here to bring `ownProps` to a single type.
            // Otherwise `ownProps` will be an object, but
            // `ownProps.parentEntity.entity` will be an immutable object and
            // we won't be able to access nested fields
            const attrValue = fromJS(ownProps).getIn(map.split('.'));
            if (attrValue) {
              entity = entity.set(attr, attrValue);
            }
          }
        }
      } else if (action === 'replicate') {
        const persistedItem = sessionStorage.getItem('entityToReplicate');
        entity = transit.fromJSON(persistedItem);
      } else {
        entity = getFulfilledEntity(
          getValidEntity(topProps, path, entityId),
          entityDef,
          resources
        );
      }

      // assuming if exist the store has all taps and flows
      const flows = topProps.getIn(['services', 'feed-service', 'entities', 'flows']);
      const taps = topProps.getIn(['services', 'feed-service', 'entities', 'taps']);
      const processors = topProps.getIn(['services', 'feed-service', 'entities', 'processors']);
      const forks = topProps.getIn(['services', 'feed-service', 'entities', 'forks']);
      const fieldsSelection = topProps.getIn([
        "fieldsSelections",
        action,
        serviceAlias,
        entityAlias,
      ]);

      return {
        screenWidth: topProps.get('screenWidth'),
        entities,
        entity,
        resources,
        searchText,
        searchTextPath,
        queryParams,
        queryParamsPath,
        fieldsSelection,
        // TODO: Make this configurable
        flows, taps, processors, forks
      };
    },
    EntityReplicateDialog: (ownProps) => {
      const { serviceAlias, entityAlias, entity } = ownProps;

      return {
        partition: topProps.getIn(['settings', 'partition']),
        serviceAlias,
        entityAlias,
        entity
      };
    },
    FeedTopology: () => {
      // assuming if exist the store has all taps and flows
      const flows = topProps.getIn(['services', SERVICE_NAMES.FEED, 'entities', 'flows']);
      const taps = topProps.getIn(['services', SERVICE_NAMES.FEED, 'entities', 'taps']);
      const processors = topProps.getIn(['services', SERVICE_NAMES.FEED, 'entities', 'processors']);
      const forks = topProps.getIn(['services', SERVICE_NAMES.FEED, 'entities', 'forks']);
      return { flows, taps, processors, forks, actions };
    },
    PopoutEntityView: ownProps => {
      const { popoutProps } = ownProps;
      const { entityAlias, entityId, serviceAlias } = ownProps.params;
      const serviceDef = serviceDefs[serviceAlias];
      const entityDef = serviceDef.entities[entityAlias];
      const endpoint = apiFactory(serviceAlias, entityAlias, ownProps.actions);
      const resources = getEntityResources(topProps, entityDef);
      const path = ['services', serviceAlias, 'entities', entityAlias];

      let entity = getFulfilledEntity(
        getValidEntity(topProps, path, entityId),
        entityDef,
        resources
      );

      if (popoutProps && popoutProps.inferredEntity) {
        // Interate through entityDef.attributes and fill them in blank as inferred entities have no values
        for (let [property, attrData] of Object.entries(entityDef.attributes)) {
          let val = attrData.type === 'object' ? [] : '';
          // Handle displayName so we can show a nice header in popout
          if (property === 'displayName') {
            // Remove 'aui:${entityType}:feed/'
            let displayName = entityId.split('/');
            displayName.shift();
            val = displayName.join('/');
          }

          entity = entity.set(property, val);
        }
      }

      entityDef.pkField = entityDef.pkField || endpoint.idProp;

      // Build up some of the props similar to Entity container including `match` as it's not passed to Popouts
      return {
        entity,
        entityDef,
        resources,
        endpoint,
        serviceAlias,
        match: {
          params: {
            entityId: entityId,
            entityAlias,
            action: 'view'
          },
          url: `/services/${serviceAlias}/${entityAlias}/${entityId}/view`
        }
      };
    },
    LandingPage: () => {
      const statusPageSummary = getAllStatusPageSummary(topProps);
      const statusPageServices = statusPageSummary.getIn(['components']);
      const filteredStatusPageServices = statusPageServices?.filter(s => s.get('group') === false);
      const announcements = statusPageSummary.getIn(['scheduled_maintenances']);
      const notifications = getNotifications(filteredStatusPageServices);
      const partition = topProps.getIn(['settings', 'partition']);
      let accountId = topProps.getIn(['settings', 'partitions', partition, 'accountId']);
      const accounts = topProps.getIn(ACCOUNTS_PATH, imMap());
      const taps = topProps.getIn(['services', SERVICE_NAMES.FEED, 'entities', 'taps'], imMap());
      const incidents = statusPageSummary.getIn(['incidents']);
      const showStatusPageLink = Boolean(shared.config.partitions[partition].statusPageId);

      if (accountId && !accounts.has(accountId)) {
        // accountId is invalid
        accountId = undefined;
      }
      return {
        taps,
        accountId,
        incidents,
        screenWidth: topProps.get('screenWidth'),
        announcements,
        notifications,
        partition,
        showStatusPageLink
      };
    },
    SidebarFilters: () => {
      const queryParamsPath = window.location.pathname.substr(1).replace(/\//g, ' - ');
      const queryParams = topProps.getIn(['userData', 'query', queryParamsPath]);
      return {
        queryParams,
        queryParamsPath
      };
    },
    TapFilters: ({ joinResources }) => {
      let resources = new Map();

      for (let { service, entity } of joinResources) {
        const sourceDataPath = ['services', service, 'entities', entity];
        resources.set(`${service}-${entity}`, getValidEntities(topProps, sourceDataPath));
      }

      return {
        actions,
        screenWidth: topProps.get('screenWidth'),
        joinResources,
        resources
      };
    },
    InputOutputTable: ({ joinResources }) => {
      let resources = new Map();

      for (let { service, entity } of joinResources) {
        const sourceDataPath = ['services', service, 'entities', entity];
        resources.set(`${service}-${entity}`, getValidEntities(topProps, sourceDataPath));
      }

      return {
        actions,
        screenWidth: topProps.get('screenWidth'),
        joinResources,
        resources
      };
    },
    FieldsSelector: ({ pageType, serviceAlias, entityAlias }) => {
      return {
        fieldsSelection: topProps.getIn([
          "fieldsSelections",
          pageType,
          serviceAlias,
          entityAlias,
        ]),
        actions,
      };
    },
    MockCreate: () => {
      return {};
    }
  };
  return propProvider;
}
