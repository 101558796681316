import React from 'react';
import { BUTTON_TYPE_SECONDARY } from '@au/core/lib/components/elements/AuButton';
import { history as browserHistory } from '../../../history';
import { wrapActionWithTracking } from '../../../utils/analyticsHelpers';
import { isAuMember } from '../../../utils/accessFunctions';
import LastFtcpMessage from '../../LastFtcpMessage';
import DefaultView from '../View';

import styles from '../../../css/components/entity_view.module.scss';

export default class VehicleView extends DefaultView {

  queryParams = { includeRelations: 'bindings', expandRelations: 'bindings' }

  constructor(props) {
    super(props);

    // must be set in the contructor so DefaultView state is not overwritten
    this.state = {
      ...this.state
    };
  }

  componentDidMount() {
    super.componentDidMount();
  }

  loadData = this.loadData.bind(this);
  loadData() {
    const { entity, endpoint } = this.props;

    // Ensure we get relations when navigating between cached data
    // Saw this issue when navigating between device and vehicle event bindings where
    // we would render but relations were missing
    if (entity.size && !entity.getIn(['relations', 'bindings'])) {
      return endpoint.get(this.entityId, this.queryParams).then(this.onAfterFetch, this.genericErrorHandler);
    }

    return super.loadData().catch(this.genericErrorHandler);
  }

  onManageBindingsBtnClick = this.onManageBindingsBtnClick.bind(this);
  onManageBindingsBtnClick() {
    //FIXME - MOVE TO linkHelper
    browserHistory.push(this.baseUrl + `/${this.entityId}/manage-bindings`);
  }

  getActions() {
    const actions = super.getActions();

    const extraActions = [{
      key: 'manage_bindings',
      type: BUTTON_TYPE_SECONDARY,
      className: styles.button,
      displayId: 'au.vehicle.manageBindings',
      onClick: this.onManageBindingsBtnClick
    }];

    extraActions.forEach(action => {
      actions.splice(1, 0, wrapActionWithTracking(action, 'Vehicle', 'View'));
    });

    return actions;
  }

  renderAdditionalContent() {
    let content = super.renderAdditionalContent();

    // Only display for Au account members
    if (isAuMember()) {
      const { entity } = this.props;
      const vin = entity.getIn(['properties', 'vin']);

      content.push(
        <LastFtcpMessage key="lastFtcpMessage" vin={vin} />
      );
    }

    return content;
  }

}
