import {DEV_PORTAL_LINK, HELP_LINK, TMC_METRICS_LINK, STATUS_PAGE_LINK} from '@au/core/lib/constants';

export const NOOP = () => {};

//FIXME - MOVE TO linkHelper
export const HOME_PATH          = '/home';
export const STATUS_PATH        = 'https://status.autonomic.com/';
export const SERVICES_PATH      = '/services';
export const SERVICE_NAMES = {
  ACCOUNTS: "accounts-service",
  COMMAND: "command-service",
  DEPLOYMENT: "deployment-service",
  FEED: "feed-service",
  FTCP: "ftcp-service",
  GEOFENCE: "geofence-service",
  GROUP: "group-service",
  IAM2: "iam2-service",
  IAM: "iam-service",
  INVENTORY: "inventory-service",
  TMC_AUDITS: "tmc-audits",
  COMMAND_MANAGEMENT: "command-management-service",
  RAW_MESSAGE: "raw-message-service"
};
export const TOPOLOGY_PATH      = '/topology';
export const FEED_EVENT_RATES_PATH = '/d/szxq7dZ7k/feed-event-rates-alpha';
export const COMPONENT_GALLERY_PATH   = '/component-gallery';
export const COMPONENT_GALLERY_PAGES  = {
  ENTITY_FRAMEWORK: "entity_framework", 
  INPUTS: "inputs", 
  FORMATTERS: "formatters", 
  FILTERS: "filters", 
  NAVIGATION: "navigation", 
  BASE_COMPONENTS: "base_components", 
  OVERVIEW_COMPONENTS: "overview_components",
  TABLE_COMPONENTS: "table_components"
};

export const FEED_PROCESSORS = SERVICE_NAMES.FEED + "-processors";
export const FEED_TAPS = SERVICE_NAMES.FEED + "-taps";
export const FEED_FORKS = SERVICE_NAMES.FEED + "-forks";
export const FEED_FLOWS = SERVICE_NAMES.FEED + "-flows";

export const RESPONSIVE_TABLE_DEFAULT_WIDTH       = 140;
export const RESPONSIVE_TABLE_DEFAULT_WEIGHT      = 1;
export const RESPONSIVE_TABLE_DEFAULT_FLEX_GROW   = 1;
export const RESPONSIVE_TABLE_DEFAULT_FLEX_SHRINK = 1;
export const RESPONSIVE_TABLE_DEFAULT_PAGE_SIZE   = 150;
export const RESPONSIVE_TABLE_PAGES_TO_PRELOAD    = 5;
export const RESPONSIVE_TABLE_PAGES_TO_CONTINUE   = 30;

export const HEADER_ROW_HEIGHT = 48;

export const DATETIME_FORMAT_HOURS   = 'HH';
export const DATETIME_FORMAT_MINUTES = 'mm';
export const DATETIME_FORMAT_DATE = 'MMM DD YYYY';
export const DATETIME_FORMAT_TIME = `${DATETIME_FORMAT_HOURS}:${DATETIME_FORMAT_MINUTES} a z`;
export const DATETIME_FORMAT_FULL = `${DATETIME_FORMAT_DATE} ${DATETIME_FORMAT_TIME}`;

export const DATADOG_RUM_URL = 'https://www.datadoghq-browser-agent.com/datadog-rum-us.js';

export const STATEMENT_HISTORY_ENDPOINT_URI = '/v1alpha/tmc-audits/statement';
export const STATEMENT_HISTORY_VIEW_PATH = '/services/iam2-service/statements';

export const MAP_TYPE_HYBRID  = 'hybrid';
export const MAP_TYPE_ROADMAP = 'roadmap';

export const GEO_SHAPE_TYPE_CIRCLE    = 'circle';
export const GEO_SHAPE_TYPE_POLYGON   = 'polygon';
export const GEO_SHAPE_TYPE_RECTANGLE = 'rectangle';

export const ARN_RE   = /^aui:(.{2,}|\*):([^/]{2,}|\*)$/;
export const AUI_RE   = /^aui:(.{2,}|\*):(.{2,}|\*)?\//;
export const EMAIL_RE = /^[a-zA-Z0-9_+&*-]+(?:\.[a-zA-Z0-9_+&*-]+)*@(?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,7}$/;
export const USERNAME_RE = /^[\S]+$/;
export const UUID_RE  = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
export const VIN_RE   = /^[A-HJ-NPR-Z0-9]{17}$/;

export const NEW_FILTER_ROW = 'data-rbd-draggable-context-id';

export const INVALID    = 'INVALID';
export const INCOMPLETE = 'INCOMPLETE';

export const FLOW_ARN         = 'aui:flow:feed';
export const FORK_ARN         = 'aui:feed:fork';
export const PROCESSOR_ARN    = 'aui:feed:processor';
export const TAP_ARN          = 'aui:tap:feed';
export const VEHICLE_ARN      = 'aui:asset:vehicle';
export const DEVICE_ARN       = 'aui:asset:device';
export const DIRECT_ROUTE_ARN = 'aui:feed:direct-route';

// HACK part of the processor-crud fix
export const PROCESSOR_CUSTOM_PREFIX = 'processor-crud-';

export const STATUS_NOT_BOUND = 'not_bound';
export const STATUS_CONNECTED = 'CONNECTED';
export const STATUS_DISCONNECTED = 'DISCONNECTED';
export const STATUS_UNKNOWN = 'unknown';

export const forkState = Object.freeze({
  RUNNING: 'running',
  STOPPED: 'stopped',
  PAUSED:  'paused',
  REPLAY:  'replay',

  // TODO: support these states soon
  // DELETED: 'deleted'
});

export const tapState = Object.freeze({
  RUNNING: 'running',
  PAUSED:  'paused',
  STOPPED: 'stopped',
  REPLAY: 'replay'
});

export const commandState = Object.freeze({
  SUCCESS: 'success',
  EXPIRED: 'expired',
  FAILURE: 'failure',
  CANCELLED: 'cancelled',
  DEVICE_RESPONSE_RECEIVED: 'device_response_received',
  RECEIVED_USER_INPUT_ALLOW: 'received_user_input_allow',
  RECEIVED_USER_INPUT_DENY: 'received_user_input_deny',
  /* Not supported by the backend service */
  // REQUESTED: 'requested',
});

export const commandDefinitionState = Object.freeze({
  DRAFT: 'command_definition_draft',
  ENABLED: 'command_definition_enabled',
  DISABLED: 'command_definition_disabled',
});

export const commandDefinitionValidity = Object.freeze({
  TRUE: 'command_definition_valid',
  FALSE: 'command_definition_invalid',
});

export const deploymentStateIcon = Object.freeze({
  REQUESTED: 'requested',
  REQUEST_DELIVERY_QUEUED: 'in_progress',
  CANCELLATION_IN_PROGRESS: 'in_progress',
  REQUEST_DELIVERY_IN_PROGRESS: 'in_progress',
  CANCELLED: 'cancelled',
  REQUEST_DELIVERY_TIMED_OUT: 'expired',
  ARTIFACT_RETRIEVAL_QUEUED: 'in_progress',
  REQUEST_DELIVERY_FAILURE: 'failure',
  ARTIFACT_RETRIEVAL_IN_PROGRESS: 'in_progress',
  INSTALLATION_QUEUED: 'in_progress',
  ARTIFACT_RETRIEVAL_FAILURE: 'failure',
  INSTALLATION_IN_PROGRESS: 'in_progress',
  DEPLOYING: 'deploying',
  INSTALLATION_FAILURE: 'failure',
  EXPIRED: 'expired',
  SUCCESS: 'success',
  FAILURE: 'failure',
  PARTIAL_SUCCESS: 'partial_success',
  PARTIAL_FAILURE: 'partial_failure',
  IN_PROGRESS: 'in_progress'
});

export const entityEvent = Object.freeze({
  CREATE_BTN_CLICK: 'createEntityBtnClick',
  CANCEL_BTN_CLICK: 'cancelEntityBtnClick',
});

export const commandDefinitionStatus = Object.freeze({
  DRAFT: 'DRAFT',
  ENABLED: 'ENABLED',
  DISABLED: 'DISABLED',
});

export const validityStatus = Object.freeze({
  valid: 'VALID',
  invalid: 'INVALID',
});

export const fsmTypeLabel = Object.freeze({
  COMMAND_WITH_RESPONSE: "commandWithResponse",
  COMMAND_WITHOUT_RESPONSE: "commandWithoutResponse",
  COMMAND_WITH_USER_INPUT: "commandWithUserInput"
});

export const statusPageServiceStatus = Object.freeze({
  OPERATIONAL: 'operational',
  PARTIAL_OUTAGE: 'partial_outage',
  DEGRADED_PERFORMANCE: 'degraded_performance',
  SCHEDULED: 'scheduled',
  IDENTIFIED: 'identified',
  MAJOR_OUTAGE: 'major_outage',
  IN_PROGRESS: 'in_progress'
  /* Postponed for now */
  // EXTERNAL_ISSUE: 'EXTERNAL_ISSUE'
});

export const serviceStatus = Object.freeze({
  UP: 'UP',
  DOWN: 'DOWN',
  DEGRADED: 'DEGRADED',
  PLANNED_MAINT: 'PLANNED_MAINT'
  /* Postponed for now */
  // EXTERNAL_ISSUE: 'EXTERNAL_ISSUE'
});

export const serviceStatusWeights = Object.freeze({
  [serviceStatus.DOWN]: 3,
  [serviceStatus.DEGRADED]: 2,
  [serviceStatus.PLANNED_MAINT]: 1,
  [serviceStatus.UP]: 0
});

export const uptimeGranularity = Object.freeze({
  HOURS: 'hrs',
  DAYS: 'days'
});

export const tapFilterType = Object.freeze({
  ATTRIBUTE:           'attributeFilter',
  ATTRIBUTE_TAG:       'attributeTagFilter',
  DEVICE:              'deviceFilter',
  GROUP:               'groupFilter',
  IAM:                 'iamFilter',
  MEMBER_PASS_THROUGH: 'memberPassThroughFilter',
  SIGNAL_PASS_THROUGH: 'signalPassThroughFilter',
  MEMBERSHIP_TAG:      'membershipTagFilter',
  VIN:                 'vinFilter',
  /* tagFitler is deprecated, but still needed to render existing records */
  TAG:            'tagFilter',
});

export const tapFilterTags = Object.freeze([
  'ATTRIBUTE',
  'ATTRIBUTE_TAG',
  'DEVICE',
  'GROUP',
  'IAM',
  'MEMBER_PASS_THROUGH',
  'MEMBERSHIP_TAG',
  'SIGNAL_PASS_THROUGH',
  'VIN'
])

export const outputFilterType = Object.freeze({
  GROUP:               'groupFilter',
  VIN:                 'vinFilter',
  MEMBER_PASS_THROUGH: 'memberPassThroughFilter'
});

export const permittedFilterTypes = Object.freeze([
  tapFilterType.GROUP,
  tapFilterType.MEMBER_PASS_THROUGH,
  tapFilterType.VIN
])

export const attributeFilterTypes = Object.freeze([
  tapFilterType.ATTRIBUTE
]);

export const signalFilterTypes = Object.freeze([
  tapFilterType.SIGNAL_PASS_THROUGH,
  tapFilterType.ATTRIBUTE,
  tapFilterType.ATTRIBUTE_TAG
])

export const membershipFilterTypes = Object.freeze([
  tapFilterType.DEVICE,
  tapFilterType.GROUP,
  tapFilterType.IAM,
  tapFilterType.MEMBERSHIP_TAG,
  tapFilterType.VIN,
  tapFilterType.MEMBER_PASS_THROUGH
]);

// TODO: utilize API to grab deviceType list when its ready - metadata endpoint is not source of truth
export const deviceType = Object.freeze({
  ECG: 'ecg',
  EPID2: 'epid2',
  FNV4: 'fnv4',
  GEOTAB: 'geotab',
  IWID: 'iwid',
  MEBOCU4: 'meb-ocu4',
  OCU3: 'ocu3',
  TBOX: 'tbox',
  TBOX3: 'tbox3',
  TCU: 'tcu',
  TCUFNV2: 'tcu-fnv2',
  TCUFB0: 'tcu-fb0',
  LYTX: 'lytx'
});

export const fsmType = Object.freeze({
  COMMAND_WITH_RESPONSE: 'COMMAND_WITH_RESPONSE',
  COMMAND_WITHOUT_RESPONSE: 'COMMAND_WITHOUT_RESPONSE',
  COMMAND_WITH_USER_INPUT: 'COMMAND_WITH_USER_INPUT'
});

// TODO: utilize API to grab deviceType list when its ready
export const commandDeviceType = Object.freeze({
  ecg: "assets/devices/ecg",
  tcu: "assets/devices/tcu",
  fnv2: "assets/devices/tcu-fnv2",
  fnv4: "assets/devices/fnv4",
  ocu3: "assets/devices/ocu3",
  ocu4: "assets/devices/meb-ocu4",
});

export const ALL_DEVICES_WILDCARD = '.*';

export const clientType = Object.freeze({
  PUBLIC: "PUBLIC",
  CONFIDENTIAL_WITH_SERVICE_ACCOUNT: "CONFIDENTIAL_WITH_SERVICE_ACCOUNT",
  CONFIDENTIAL_WITHOUT_SERVICE_ACCOUNT: "CONFIDENTIAL_WITHOUT_SERVICE_ACCOUNT"
});

export const policyType = Object.freeze({
  SUBJECT: 'subject',
  RESOURCE: 'resource'
});

export const policyGroup = Object.freeze({
  DIRECT: 'd',
  OVERLAY: 'o',
  MEMBERSHIP: 'm',
  ALL: 'dom'
});

export const statementEffect = Object.freeze({
  ALLOW: 'ALLOW',
  DENY: 'DENY'
});

export const knownPolicyProps = ['statements'];
export const knownStatementProps = ['effect', 'entitlements', 'description', 'condition'];

export const processorState = Object.freeze({
  STARTING: 'starting',
  RUNNING: 'running',
  PAUSING: 'pausing',
  PAUSED: 'paused',
  STOPPING: 'stopping',
  STOPPED: 'stopped',
});

export const memberType = Object.freeze({
  VEHICLE: 'vehicle',
  DEVICE: 'device'
});

export const userGroupMemberType = Object.freeze({
  USER: 'user',
  CLIENT: 'client'
});

export const environmentQueryParams = Object.freeze({
  partition: 'aP',
  account:   'aA'
});

export const customCredsQueryParams = Object.freeze({
  client: 'useMyClient',
  token:  'useMyToken'
});

//This is for the ordered set of links to insure that link order stays the same.
export const tmcLinkTypes = Object.freeze([
  DEV_PORTAL_LINK,
  TMC_METRICS_LINK,
  HELP_LINK,
  STATUS_PAGE_LINK
]);

export const regionCodeType = Object.freeze({
  NA: 'NA', // North America
  CN: 'CN', // China
  EU: 'EU', // Europe
  RW: 'RW', // Rest of the World
  NC: 'NC', // Not Configured
  /* deprecated, but still in use */
  SA: 'SA', // South America
});

export const dateTimeFormatToken = Object.freeze({
  's': 'seconds',
  'm': 'minutes',
  'h': 'hours',
  'd': 'days',
  'M': 'months'
});

export const ftcpTelemetryStageFields = Object.freeze({
  'FTCP_INGEST': ['ingestSource'],
  'FTCP_DECRYPTOR_RAW_DECRYPTED': [
    'auIngestMsgId',
    'messageType',
    'rawFtcpMessageName'
  ],
  'FTCP_CONVERTER': [
    'auIngestMsgId',
    'ftcpVersion',
    'messageType',
    'rawFtcpMessageName',
    'canDB',
    'metricsConverted',
    'busArch',
    'messageTimestamp'
  ]
});

export const vehiclePropertyFilterValues = Object.freeze([
  {displayString: "vin", val: "vin"},
  {displayString: "canBusArch", val: "canBusArch"},
  {displayString: "engineDescription", val: "engineDescription"},
  {displayString: "engineFeatureCode", val: "engineFeatureCode"},
  {displayString: "engineFuelCapabilityCode", val: "engineFuelCapabilityCode"},
  {displayString: "engineFuelCapabilityDescription", val: "engineFuelCapabilityDescription"},
  {displayString: "fuelTankPartNumber", val: "fuelTankPartNumber"},
  {displayString: "fuelType", val: "fuelType"},
  {displayString: "make", val: "make"},
  {displayString: "mmota_campaignID", val: "mmota_campaignID"},
  {displayString: "mmota_domain_domainInstance", val: "mmota_domain_domainInstance"},
  {displayString: "mmota_domain_domainInstanceVersion", val: "mmota_domain_domainInstanceVersion"},
  {displayString: "mmota_domain_name", val: "mmota_domain_Name"},
  {displayString: "mmota_manifestSchema", val: "mmota_manifestSchema"},
  {displayString: "mmota_policyTableVersion", val: "mmota_policyTableVersion"},
  {displayString: "mmota_requestRole_Role", val: "mmota_requestRole_Role"},
  {displayString: "mmota_requestRole_RoleDescription", val: "mmota_requestRole_RoleDescription"},
  {displayString: "mmota_swumVersion", val: "mmota_swumVersion"},
  {displayString: "mmota_timeStamp", val: "mmota_timeStamp"},
  {displayString: "mmota_triggerType", val: "mmota_triggerType"},
  {displayString: "mmota_vilOrder", val: "mmota_vilOrder"},
  {displayString: "mmota_vilReason", val: "mmota_vilReason"},
  {displayString: "mmota_visVersion", val: "mmota_visVersion"},
  {displayString: "model", val: "model"},
  {displayString: "moduleUpdateTime", val: "moduleUpdateTime"},
  {displayString: "productType", val: "productType"},
  {displayString: "steeringPosition", val: "steeringPosition"},
  {displayString: "transmissionCode", val: "transmissionCode"},
  {displayString: "transmissionDescription", val: "transmissionDescription"},
  {displayString: "transmissionType", val: "transmissionType"},
  {displayString: "trim", val: "trim"},
  {displayString: "vehicleLineFeatureCode", val: "vehicleLineFeatureCode"},
  {displayString: "vil_last_updated_at", val: "vil_last_updated_at"},
  {displayString: "year", val: "year"}
]);

export const devicePropertyFilterValues = Object.freeze([
  {displayString: "assemblyPartNumber", val: "assemblyPartNumber"},
  {displayString: "bluetoothMacAddress", val: "bluetoothMacAddress"},
  {displayString: "canDbVersion", val: "canDbVersion"},
  {displayString: "ecallIccid", val: "ecallIccid"},
  {displayString: "embeddedUiccid", val: "embeddedUiccid"},
  {displayString: "hardwarePartNumber", val: "hardwarePartNumber"},
  {displayString: "iccid", val: "iccid"},
  {displayString: "imei", val: "imei"},
  {displayString: "imsi", val: "imsi"},
  {displayString: "manufacturingFacilityCode", val: "manufacturingFacilityCode"},
  {displayString: "msisdn", val: "msisdn"},
  {displayString: "oem", val: "oem"},
  {displayString: "packageid", val: "packageid"},
  {displayString: "plantShipDate", val: "plantShipDate"},
  {displayString: "protocol", val: "protocol"},
  {displayString: "protocolVersion", val: "protocolVersion"},
  {displayString: "regionCode", val: "regionCode"},
  {displayString: "serial", val: "serial"},
  {displayString: "simulated", val: "simulated"},
  {displayString: "supplier", val: "supplier"},
  {displayString: "wifiMacAddress", val: "wifiMacAddress"}
]);

export const FILTER_PERMIT = 'PERMIT';

export const TAP_VALIDATION_MODES = Object.freeze({
  PERMIT: 'permit',
  MEMBERSHIP: 'membership',
  ATTRIBUTE: 'attribute',
  WILDCARD: 'wildcard',
  ATTRIBUTE_TAGS: 'attributeTags',
  SIGNAL: 'signal'
});

export const TAP_FILTERS_MODES = Object.freeze({
  VIEW: 'view',
  EDIT: 'edit',
  CREATE: 'create'
});

export const COMMAND_MANAGEMENT_DEFINITION = Object.freeze({
  ID:           'id',
  TYPE:         'type',
  VERSION:      'version',
  DRAFT:        'draft',
  ENABLED:      'enabled',
  DISABLED:     'disabled',
  VALID:        'valid',
  NUM_DRAFTS:   'numDrafts',
  VERSION_ENTITIES: 'versionEntities',
  COMMAND_DEFINITION_STATUS: 'commandDefinitionStatus',
});

export const COMMAND_TYPES_TO_HIDE = Object.freeze({
  REMOTEDIAGNOSTIC:                    'remoteDiagnostic',
  ONLINETRAFFICDATAMESSAGE:            'onlineTrafficDataMessage',
  ONLINETRAFFICDATAACKNOWLEDGEMENT:    'onlineTrafficDataAcknowledgement',
  ONLINETRAFFICSESSIONACKNOWLEDGEMENT: 'onlineTrafficSessionAcknowledgement'
});

export const POU_OPTIONS = Object.freeze([
  { displayString: '1093', val: '1093', subTitle: 'Use Vehicle Data for Marketing Model Creation', item: { displayString: '1093', val: '1093', subTitle: 'Use Vehicle Data for Marketing Model Creation' } },
  { displayString: '1096', val: '1096', subTitle: 'Use Vehicle Data to Identify Target Audience for Marketing Use Case' },
  { displayString: '1094', val: '1094', subTitle: 'Use Driving Data for Marketing Model Creation' },
  { displayString: '1097', val: '1097', subTitle: 'Use Driving Data to Identify Target Audience for Marketing Use Case' },
  { displayString: '1095', val: '1095', subTitle: 'Use Vehicle Location for Marketing Model Creation' },
  { displayString: '1098', val: '1098', subTitle: 'Use Vehicle Location to Identify Target Audience for Marketing Use Case' },
  { displayString: '1002', val: '1002', subTitle: 'Use Vehicle Data for Research & Development' },
  { displayString: '1089', val: '1089', subTitle: 'Use Driving Data for Research & Development' },
  { displayString: '1090', val: '1090', subTitle: 'Use Vehicle Location for Research & Development' },
]);

export const PROD_LINK    = 'https://console.autonomic.ai';
export const STAGING_LINK = 'https://console.beta.autonomic.ai';
export const DEV_LINK     = 'https://console.dev.instrumented-mile.com';

export const PROD_ENV = 'production';
export const STAGING_ENV = 'staging';
export const DEV_ENV = 'dev';