import React from 'react';

import AutoIntl from '@au/core/lib/components/elements/AutoIntl';

import { get } from '../../../utils/api';
import {
  STATUS_NOT_BOUND,
  STATUS_CONNECTED,
  STATUS_DISCONNECTED,
  STATUS_UNKNOWN, SERVICE_NAMES
} from '../../../constants';
import WidgetTile from '../../WidgetTile';
import WidgetText from '../../WidgetText';
import WidgetIcon from '../../WidgetIcon';
import SidebarSubviews from "../../SidebarSubview";
import VehicleView from './VehicleView';

import styles from '../../../css/components/vehicle_overview.module.scss';

const DevicePriorityOrder = {
  ecg: 1,
  tcu: 2,
  'tcu-fnv2': 2,
  epid2: 3,
  iwid: 3,
  tbox: 3,
  ocu3: 4,
  'meb-ocu4': 4,
  geotab: 5,
};

export default class VehicleOverview extends VehicleView {

  state = {
    ...this.state,
    isConnected: STATUS_UNKNOWN
  }

  static defaultProps = {
    deviceTypes: []
  }

  async componentDidUpdate(prevProps, prevState) {
    super.componentDidUpdate(prevProps, prevState);
    const { entity } = this.props;
    let provisionedDevices = entity.getIn(['relations', 'bindings']);
    const provisioned = provisionedDevices?.size > 0;

    if (provisionedDevices?.size > 1) {
      // priority sort
      const sortedprovisionedDevicesArray = Array.from(provisionedDevices).sort((adevice, bdevice) => {
        const aDeviceType = adevice.getIn(['type']);
        const bDeviceType = bdevice.getIn(['type']);
        return DevicePriorityOrder[aDeviceType] - DevicePriorityOrder[bDeviceType];
      });
      provisionedDevices = sortedprovisionedDevicesArray;
    }

    if (provisioned && this.state.isConnected === STATUS_UNKNOWN || this.state.isConnected === undefined) {
      let isConnected;
      for (let i = Array.from(provisionedDevices).length-1; i >= 0; i--) {
        isConnected =  await this.fetchDevCon();
        if (isConnected === STATUS_CONNECTED) {
          break;
        }
      }
      this.setState({ isConnected });
    }
  }

  fetchDevCon = this.fetchDevCon.bind(this)
  async fetchDevCon() {
    const vin = this.props.entity.toJS().properties.vin;
    const resp = await get(`/v1/device-connectivity/${vin}/current-state`)
      .catch(r => r);
    return resp?.data?.state;
  }

  provisionedEntities() {
    const { entity } = this.props;
    let provisionedDevices = entity.getIn(['relations', 'bindings']);
    if(provisionedDevices.size > 1) {
      // priority sort
      const sortedprovisionedDevicesArray = Array.from(provisionedDevices).sort((adevice, bdevice) => {
        const aDeviceType = adevice.getIn(['type']);
        const bDeviceType = bdevice.getIn(['type']);
        return DevicePriorityOrder[aDeviceType] - DevicePriorityOrder[bDeviceType];
      });
      provisionedDevices = new Set(sortedprovisionedDevicesArray);
    }
    return provisionedDevices?.reduce((entities, device) => {
      let type = device.getIn(['type']);
      let id = device.getIn(['id']);

      const deviceUrl = `/services/${SERVICE_NAMES.INVENTORY}/devices/${id}/view`;

      entities.deviceTypes.push(<AutoIntl key={`key_${type}`} className={styles.device_type} displayString={type} to={deviceUrl} tag='link' />);
      return entities;
    }, { deviceTypes: [] });
  }

  connectionStatus() {
    const { entity } = this.props;
    const { isConnected } = this.state;

    const provisionedDevices = entity.getIn(['relations', 'bindings']);
    const provisioned = provisionedDevices?.size > 0;

    let connectionStatus;
    let displayId;

    if (!provisioned) {
      connectionStatus = STATUS_NOT_BOUND;
      displayId = 'au.entity.notProvisioned';
    } else if (provisioned && isConnected === STATUS_CONNECTED) {
      connectionStatus = STATUS_CONNECTED.toLowerCase();
      displayId = 'au.entity.connected';
    } else if (provisioned && isConnected === STATUS_DISCONNECTED) {
      connectionStatus = STATUS_DISCONNECTED.toLowerCase();
      displayId = 'au.entity.notConnected';
    } else {
      connectionStatus = STATUS_UNKNOWN;
      displayId = 'au.entity.unknown';
    }

    return { connectionStatus, displayId };
  }

  renderWidget({ icon, displayId, children  }) {
    return <WidgetTile icon={icon}>
      <WidgetIcon icon={icon} />
      <WidgetText titleDisplayId={displayId}>
        { children }
      </WidgetText>
    </WidgetTile>;
  }

  renderWidgets() {
    const { entity } = this.props;

    if (!entity.hasIn(['relations', 'bindings'])) return;

    const provisionedDevices = entity.getIn(['relations', 'bindings']);
    const provisioned = provisionedDevices?.size > 0;

    const { deviceTypes } = this.provisionedEntities();

    const { displayId, connectionStatus } = this.connectionStatus();

    return <div className={styles.widget_container}>
      <div className={styles.widget_section}>
        <div className={styles.first_widget}>{ this.renderWidget({ icon: connectionStatus, displayId: provisioned ? 'au.entity.connectivityStatus' : 'au.entity.provisionStatus', children: <AutoIntl className={styles.status_value} displayId={displayId} />}) }</div>
        <div className={styles.second_widget}>{ this.renderWidget({ icon: 'device', displayId: 'au.entity.provisionedDevices', children: provisioned && deviceTypes || !provisioned && <AutoIntl className={styles.status_value} displayId={'au.entity.noProvisionedDevice'} />}) }</div>
      </div>
    </div>;
  }

  renderContent() {
    const {screenWidth} = this.props;
    const isMobile = screenWidth !== "desktop";

    return (
      <div className={styles.content}>
        <div className={styles.content_flex}>
          <SidebarSubviews
            navLinks={this.getNavLinks()}
            portalRef={this._leftSidebarRef}
            open={this.state.subviewSidebarOpen}
            setOpen={this.setSubviewSidebar}
            isMobile={isMobile}
          />
          <div className={styles.table}>
            <div className={styles.section}>
              {this.renderWidgets()}
            </div>
          </div>
        </div>
      </div>
    );
  }
}