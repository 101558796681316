import TMC from '@autonomic/browser-sdk';
import DefaultList from '../List';
import { generateEntityPath } from '../../../utils/entity';

export default class AuditList extends DefaultList {

  static defaultProps = {
    ...super.defaultProps,
    pageSize: 200
  }

  auditEndpoint = new TMC.services.TmcAudits({ apiVersion: '1-alpha' }).audit;

  list() {
    const { pageSize } = this.props;

    let data = { pageSize, ...this.state.filterQueryParams };

    return this.auditEndpoint.listAudits(data);
  }

  onListSuccess(resp) {
    const { actions, entityDef, endpoint, serviceAlias } = this.props;
    actions.listEntitiesSuccess({
      path: generateEntityPath(endpoint, serviceAlias),
      data: resp.items,
      pkField: entityDef.pkField,
      replace: resp.isFirstPage
    });

    return resp;
  }

}
