import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import PropProvider from "../PropProvider";
import * as ActionCreators from "../ActionCreators";
import FieldsSelector from "../components/FieldSelector";

export default connect(
  (state, ownProps) => PropProvider(state).FieldsSelector(ownProps),
  (dispatch) => ({ actions: bindActionCreators(ActionCreators, dispatch) })
)(FieldsSelector);