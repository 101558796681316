import {generateEntityPath} from '../../../utils/entity';
import AuditList from "./AuditList";

export default class SubEntityAuditList extends AuditList {

  list() {
    const { entityDef, pageSize, parentEntity } = this.props;
    const { queryParams } = entityDef;

    let data = {
      pageSize,
      ...queryParams,
      clientId: parentEntity.entity.getIn(['id']),
      resourceAui: parentEntity.entity.getIn(['aui']),
      username: parentEntity.entity.getIn(['username']),
      ...this.state.filterQueryParams,
    };

    if (data.resourceAui !== undefined || data.username !== undefined) {
      delete data.clientId;
    }

    return this.auditEndpoint.listAudits(data);
  }

  onListSuccess = this.onListSuccess.bind(this);
  onListSuccess(resp) {
    const { entityDef, parentEntity, actions } = this.props;
    const path = [
      ...generateEntityPath(parentEntity.endpoint, parentEntity.serviceAlias),
      parentEntity.entityId,
      'audit'
    ];

    actions.listEntitiesSuccess({
      path,
      data: resp.items,
      pkField: entityDef.pkField,
      replace: resp.isFirstPage
    });
  }
}