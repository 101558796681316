import { upperFirst } from 'lodash';

import { history as browserHistory } from './history';
import { guessCurrentTimezone } from './utils/timezones';
import { getAccess } from './utils/accessFunctions';
import { augmentGeofence as coreAugmentGeofence } from '@au/core/lib/utils/maps';

function augmentGeofence(geofence) {
  geofence = coreAugmentGeofence(geofence);

  if (geofence.timezone) {
    return Promise.resolve(geofence);
  }

  return guessCurrentTimezone(geofence.center)
    .then(tz => {
      geofence.timzone = tz;
      return geofence;
    })
    .catch(error => {
      console.warn(error); // eslint-disable-line no-console
      //throw(error); // TODO: figure out how to handle no timezone returned
      return geofence;
    });
}

export function screenWidthChanged(width) {
  return {
    type: "SCREEN_WIDTH_CHANGED",
    width: width
  };
}

export function setSystemOfUnits(systemOfUnits) {
  return {
    type: 'SET_SYSTEM_OF_UNITS',
    systemOfUnits
  };
}

export function setLocale(locale) {
  return {
    type: 'SET_LOCALE',
    locale
  };
}

export function setPageTitle(title) {
  return {
    type: 'SET_PAGE_TITLE',
    title
  };
}

export function setPageSchema(schema) {
  return {
    type: 'SET_PAGE_SCHEMA',
    schema
  };
}

export function saveUserData(data, path) {
  return {
    type: 'SAVE_USER_DATA',
    data,
    path
  };
}

/**
 * Used to trigger a change in the minimap viewed area bounding box
 * representation.
 */
export function mapVisibleBoundsChange(bounds) {
  return {
    type: "MAP_VISIBLE_BOUNDS_CHANGE",
    bounds
  };
}

export function mapChange(center, zoom) {
  return {
    type: "MAP_CHANGE",
    center: center,
    zoom: zoom
  };
}

export function geofencesMapChange(center, zoom) {
  return {
    type: "GEOFENCES_MAP_CHANGE",
    center: center,
    zoom: zoom
  };
}

export function mapMarkerHover(vehicle_id) {
  return {
    type: "MAP_MARKER_HOVER",
    hoveredInTime: Date.now(),
    vehicle_id
  };
}

export function mapMarkerEndHover(msDelay=0) {
  const hoveredOutTime = Date.now();
  return function(dispatch) {
    setTimeout(function() {
      dispatch({
        type: "MAP_MARKER_END_HOVER",
        hoveredOutTime
      });
    }, msDelay);
  };
}

export function mapToggleViewClick() {
  return {
    type: "MAP_TOGGLE_VIEW_CLICK"
  };
}

export function openPopout(popoutProps) {
  return {
    type: 'OPEN_POPOUT',
    popoutProps: {
      maintainWidth: true,
      ...popoutProps
    }
  };
}

export function closePopout() {
  return {
    type: 'CLOSE_POPOUT'
  };
}

export function persistPopoutWidth(width) {
  return {
    type: 'PERSIST_POPOUT_WIDTH',
    width
  };
}

export function resetPopoutWidth() {
  return {
    type: 'RESET_POPOUT_WIDTH'
  };
}

function showContent(dispatch, getState, path, popoutProps) {
  if (getState().get('screenWidth') === 'desktop') {
    dispatch(openPopout(popoutProps));
  }
  else {
    if (path) {
      browserHistory.push(path);
    }
    dispatch({ type: "NOOP" });
  }
}

export function openJsonViewer(url, data, props) {
  return (dispatch, getState) => showContent(dispatch, getState, url, {
    props: { nuevo: true, resizable: true, buttonClassName: `json_button` },
    componentName: 'JsonViewer',
    componentProps: {
      ...props,
      key: 'json-viewer',
      data
    }
  });
}

// TODO: This might be better suited to accept an object as params and destructured from there
// currently you need to pass in nulls for empty values in between other params
// ex - openEntityPage(entityType, entityAlias, null, action, null, props)
// https://www.pivotaltracker.com/story/show/174074225
export function openEntityPage(entityType, entityAlias, entityId, action, entryId, serviceAlias, props={}) {
  //FIXME - MOVE TO linkHelper
  const path = typeof entityId === 'undefined' && typeof action === 'undefined'
               ? 'create'
               : `${encodeURIComponent(entityId)}/${action}` + (entryId ? `/${entryId}` : '');
  const url = `/${entityAlias}/${path}`;

  return (dispatch, getState) => showContent(dispatch, getState, url, {
    props: props,
    componentName: `Connected${upperFirst(entityType)}${upperFirst(action || 'edit')}`,
    componentProps: {
      params: {
        [`${entityType}Id`]: entityId,
        entityId,
        entityType,
        entryId,
        entityAlias,
        serviceAlias
      },
      key: `${entityType}-${action}-${entityId}` + (entryId ? `/${entryId}` : '') + '-form',
      popoutProps: props.componentProps
    }
  });
}

export function openEntityJsonViewer(entityId, data, props) {
  //FIXME - MOVE TO linkHelper
  const baseUrl = window.location.pathname.split('/').slice(0, -1).join('/');
  const url = `${baseUrl}/${encodeURIComponent(entityId)}/json-view`;

  return (dispatch, getState) => openJsonViewer(url, data, props)(dispatch, getState);
}

export function listGeofenceSuccess(args) {
  if (!getAccess().geofence.read){
    return () => Promise.resolve();
  }

  return (dispatch) => {
    for (let geofence of args.data) {
      augmentGeofence(geofence);
    }
    dispatch({ type: 'LIST_ENTITIES_SUCCESS', ...args });
  };
}
// reference generated dynamicaly in utils/api:enhanceEndpoint
export function replaceGeofenceSuccess(args) {
  return (dispatch) => {
    const { data: geofence } = args;
    augmentGeofence(geofence);
    dispatch({ type: 'UPDATE_ENTITY_SUCCESS', ...args });
  };
}
// reference generated dynamicaly in utils/api:enhanceEndpoint
export function createGeofenceSuccess(args) {
  return (dispatch) => {
    const { data: geofence } = args;
    augmentGeofence(geofence);
    dispatch({ type: 'CREATE_ENTITY_SUCCESS', ...args });
  };
}
// reference generated dynamicaly in utils/api:enhanceEndpoint
export function deleteGeofenceSuccess(args) {
  return deleteEntitySuccess(args);
}

function fetchStatusPageSummarySuccess(summary) {
  return { type: 'FETCH_STATUS_PAGE_SUMMARY_SUCCESS', summary };
}

export function fetchStatusPageSummary() {
  return (dispatch, getState) => {
    const state = getState();
    if (!state.getIn(['settings', 'partition'])
      && !state.getIn(['settings', 'partitions', state.getIn(['settings', 'partition']), 'accountId'])) {
      return { type: 'NOOP' };
    }
    return window.SP?.summary({
      success: function (data) {
        dispatch(fetchStatusPageSummarySuccess(data));
      },
      error: function () {
      }
    });
  };
}

export function setPartition(partitionKey) {
  return { type: 'SET_SETTING', name: 'partition', 'value':partitionKey };
}

export function setAccountId(accountId) {
  return { type: 'SET_ACCOUNT_ID', accountId: accountId };
}

export function setFieldsSelection(args) {
  return { type: "SET_FIELDS_SELECTION", ...args };
}

export function showAccountDialog() {
  return { type: 'SHOW_ACCOUNT_DIALOG' };
}

export function hideAccountDialog() {
  return { type: 'HIDE_ACCOUNT_DIALOG' };
}

// reference generated dynamicaly in utils/api::apiFactory
export function listEntitiesSuccess(args) {
  return { type: 'LIST_ENTITIES_SUCCESS', ...args };
}

// reference generated dynamicaly in utils/api::apiFactory
export function getEntitySuccess(args) {
  return { type: 'GET_ENTITY_SUCCESS', ...args };
}

// reference generated dynamicaly in utils/api::apiFactory
export function pauseEntitySuccess(args) {
  return { type: 'PAUSE_ENTITY_SUCCESS', ...args };
}

// reference generated dynamicaly in utils/api::apiFactory
export function startEntitySuccess(args) {
  return { type: 'START_ENTITY_SUCCESS', ...args };
}

// reference generated dynamicaly in utils/api::apiFactory
export function createEntitySuccess(args) {
  return { type: 'CREATE_ENTITY_SUCCESS', ...args };
}

// reference generated dynamicaly in utils/api::apiFactory
export function replaceEntitySuccess(args) {
  return { type: 'UPDATE_ENTITY_SUCCESS', replace: true, ...args };
}

// reference generated dynamicaly in utils/api::apiFactory
export function patchEntitySuccess(args) {
  return { type: 'UPDATE_ENTITY_SUCCESS', ...args };
}

// reference generated dynamicaly in utils/api::apiFactory
export function deleteEntitySuccess(args) {
  return { type: 'DELETE_ENTITY_SUCCESS', ...args };
}

// Handle adding Vehicles via group/manage-assets
// export function addVehiclesEntitySuccess(args) {
//   return { type: 'UPDATE_ENTITY_SUCCESS', ...args };
// }

// export function removeVehiclesEntitySuccess(args) {
//   return { type: 'UPDATE_ENTITY_SUCCESS', ...args };
// }

// // Handle adding Devices via group/manage-asset
// export function addDevicesEntitySuccess(args) {
//   return { type: 'UPDATE_ENTITY_SUCCESS', ...args };
// }

// export function removeDevicesEntitySuccess(args) {
//   return { type: 'UPDATE_ENTITY_SUCCESS', ...args };
// }
