import React from "react";
import * as T from "prop-types";
import cn from "classnames";

import TMC from "@autonomic/browser-sdk";
import { createResponseAlertMessage } from "@au/core/lib/components/objects/AlertMessage";
import AuButton, { BUTTON_TYPE_PRIMARY, BUTTON_TYPE_SECONDARY, BUTTON_TYPE_PLAIN } from "@au/core/lib/components/elements/AuButton";
import Tabs from '@au/core/lib/components/elements/Tabs';
import AuDropDown from "@au/core/lib/components/elements/AuDropDown";
import AutoIntl from "@au/core/lib/components/elements/AutoIntl";
import MultiSelectDropDown from "@au/core/lib/components/elements/MultiSelectDropDown";
import { POU_OPTIONS } from "../constants";
import { history as browserHistory } from "../history";
import { formatMessage } from "../utils/reactIntl";
import Breadcrumbs from "./entity/Breadcrumbs";
import TagEditor from "./TagEditor";
import SimpleTable, { Cell, HeaderCell, Row } from "./SimpleTable";

import styles from "../css/components/permits_lookup.module.scss";

const applicationColumnDefs = [
  { property: 'id', labelId: 'au.permitsLookup.ruleId', width: 300, className: styles.align_left },
  { property: 'name', labelId: 'au.permitsLookup.ruleName', width: 300, className: styles.align_left },
  { property: 'remove', labelId: 'au.permitsLookup.remove', width: 90, className: styles.remove_column },
];

const propertiesColumnDefs = [
  { property: 'id', labelId: 'au.permitsLookup.groupId', width: 300 },
  { property: 'name', labelId: 'au.permitsLookup.groupName', width: 300 },
  { property: 'remove', labelId: 'au.permitsLookup.remove', width: 90, className: styles.remove_column },
];
export default class PermitsLookup extends React.Component {

  rulesEndpoint = new TMC.services[`${'Processing-crud'}`]({ apiVersion: '1'}).rules;
  messagesEndpoint = new TMC.services[`${'Processing-crud'}`]({ apiVersion: '1'}).mappedmessages;
  versionsEndpoint = new TMC.services[`${'Processing-crud'}`]({ apiVersion: '1'}).mappedftcpversions;
  groupsEndpoint = new TMC.services.Inventory({ apiVersion: '1-beta' }).groups;

  static propTypes = {
    tabs: T.array,
    initTab: T.oneOf(['application', 'properties'])
  }

  static defaultProps = {
    tabs: ['application', 'properties'],
    initTab: 'application'
  }

  constructor(props) {
    super(props);

    this.state = {
      tab: this.props.initTab,
      propertiesTableData: [],
      applicableRulesTableData: [],
      selectedRuleIds: [],
      selectedRuleNameId: "",
      selectedRuleName: "",
      selectedGroupId: "",
      selectedGroupName: "",
      rules: [],
      versions: [],
      messageOptions: [],
      topLevelMessageNameOptions: [],
      groups: [],
      namespace: "",
      topLevelMessageName: "",
      lookup: {},
      tags: {},
      consentsByPOU: {},
      showRuleNameError: true,
      pouOptions: POU_OPTIONS
    };

    this.baseUrl = props.match.url.split('/').slice(0, -1).join('/');
  }

  ruleNameRef = React.createRef();
  groupNameRef = React.createRef();

  componentDidMount() {
    this.rulesEndpoint.list().then((resp) => {
      let items = resp.data.map(item => {
        return { caption: item.ruleId, subCaption: item.name, value: item, displayString: item.name, val: item.ruleId }
      });
      this.setState({ rules: items });
    }).catch(createResponseAlertMessage);

    this.versionsEndpoint.mappedftcpversions({"ruleIds": []}).then((resp) => {
      let versionOptions = resp.data.map(version => {
        return { displayString: version, val: version };
      });
      this.setState({ versions: versionOptions });
    }).catch(createResponseAlertMessage);

    this.messagesEndpoint.mappedmessages({"ftcpVersions": []}).then((resp => {
      let messageOptions = resp.data.map(message => {
        return { displayString: message, val: message };
      });
      this.setState({ topLevelMessageNameOptions: messageOptions, messageOptions })
    })).catch(createResponseAlertMessage);

    this.groupsEndpoint.list().then((resp) => {
      let items = resp.data.items.map(item => {
        return { displayString: item.id, val: item.id, subTitle: item.displayName, item }
      });
      this.setState({ groups: items });
    }).catch(createResponseAlertMessage);
  }

  removeFromTable(itemToRemove) {
    const { propertiesTableData, applicableRulesTableData, tab } = this.state;
    let currentData = [];
    let currentTable = tab == 'properties' ? propertiesTableData : applicableRulesTableData;
    let tableName = tab == 'properties' ? 'propertiesTableData' : 'applicableRulesTableData';

    currentTable?.map(item => {
      if (item[0] !== itemToRemove.val) {
        currentData.push(item);
      }
    })

    let applicableRuleIds = [];
    let groupIds = [];

    if (currentData.length !== 0) {
      currentData.map(data => {
        tab == 'properties' ? groupIds.push(data[0]) : applicableRuleIds.push(data[0]);
      });
    }

    this.setState({ [tableName]: currentData }, this.updateLookupObject(tab == 'properties' ? 'groupIds' : 'applicableRuleIds', tab == 'properties' ? groupIds : applicableRuleIds));
  }

  addToTable() {
    const { tab, selectedGroupId, propertiesTableData, groups, selectedRuleIds, applicableRulesTableData, selectedRuleNameId, rules } = this.state;
    let items = tab == 'properties' ? groups : rules;
    let table = tab == 'properties' ? propertiesTableData : applicableRulesTableData;

    items.map(item => {
      if (tab == 'properties' ? selectedGroupId == item.val : selectedRuleIds.includes(item.val) || selectedRuleNameId.length !== 0 && selectedRuleNameId == item.val) {
        let itemToAdd = [];
        itemToAdd.push(
          item.val,
          tab == 'properties' ? item.subTitle : item.displayString,
          <button
            className={styles.remove_button}
            onClick={() => this.removeFromTable(item)}
          />
        )
        table.push(itemToAdd);
      }
    });

      let applicableRuleIds = [];
      let groupIds = [];
  
      if (table.length !== 0) {
        table.map(data => {
          tab == 'properties' ? groupIds.push(data[0]) : applicableRuleIds.push(data[0]);
        });
      }

    if (tab == 'properties') {
      this.groupNameRef.current.reset();
      return this.setState({ propertiesTableData: table, selectedGroupId: '' }, this.updateLookupObject('groupIds', groupIds));
    } else {
      this.ruleNameRef.current.reset();
      return this.setState(({ applicableRulesTableData: table, selectedRuleIds: [], selectedRuleNameId: '', selectedRuleName: '' }), this.updateLookupObject('applicableRuleIds', applicableRuleIds));
    }
  }

  lookupPermits() {
    const { tab, lookup, applicableRulesTableData, propertiesTableData } = this.state;

    let lookupParams = {...lookup};

    if (tab == 'properties') {
      delete lookupParams.applicableRuleIds;
      if (lookupParams.groupIds) {
        sessionStorage.setItem('groups', JSON.stringify(propertiesTableData));
      }
    }
    if (tab == 'application') {
      delete lookupParams.consentsByPOU;
      delete lookupParams.tags;
      delete lookupParams.groups;
      if (lookupParams.applicableRuleIds){
        sessionStorage.setItem('rules', JSON.stringify(applicableRulesTableData));
      }
    }

    sessionStorage.setItem('permitsLookup', JSON.stringify(lookupParams));

    browserHistory.push({
      pathname: this.baseUrl + '/result',
      state: { prevUrl: this.baseUrl + `/lookup` }
    })
  }

  handleRuleNameChange= this.handleRuleNameChange.bind(this);
  handleRuleNameChange(rule) {
    const { rules } = this.state;

    this.setState({ selectedRuleNameId: rule, showRuleNameError: true });

    rules.forEach(option => {
      if (option.val === rule) {
        return this.setState({ selectedRuleName: option.displayString, showRuleNameError: false});
      }
    });
  }

  handleGroupNameChange = this.handleGroupNameChange.bind(this);
  handleGroupNameChange(group) {
    this.setState({ selectedGroupId: group });
  }

  toggleRuleId = this.toggleRuleId.bind(this);
  toggleRuleId(rule) {
    const { selectedRuleIds } = this.state;

    if (selectedRuleIds.includes(rule)) {
      const index = selectedRuleIds.indexOf(rule)
      selectedRuleIds.splice(index, 1)
      return this.setState({ selectedRuleIds });
    }
    else if (!selectedRuleIds.includes(rule)) {
      selectedRuleIds.push(rule);
      return this.setState({ selectedRuleIds: selectedRuleIds });
    }
  }

  getDropDownOptions(options, field) {
    let unselectedOptions = [];

    if (field.length == 0) {
      return options;
    } else {
      options.map(option => {
        if (field !== option.val) {
          unselectedOptions.push({ displayString: option.displayString, val: option.val });
        }
      });
      return unselectedOptions;
    }
  }

  getRuleIdOptions() {
    const { applicableRulesTableData, selectedRuleNameId, rules } = this.state;

    let unselectedRules = [];
    let tableRuleIds = [];
    
    applicableRulesTableData.map(rule => {
      tableRuleIds.push(rule[0]);
    })

    if (selectedRuleNameId !== undefined && selectedRuleNameId.length !== 0) {
      rules.map(rule => {
        if (rule.val !== selectedRuleNameId) {
          unselectedRules.push(rule);
        }
      })
      return unselectedRules;
    }

    if (applicableRulesTableData.length !== 0) {
      rules.map(rule => {
        if (!tableRuleIds.includes(rule.val)) {
          unselectedRules.push(rule);
        }
      })
      return unselectedRules;
    } else {
      return rules;
    }
  }

  getOptions(tableData, field, items) {
    let unselectedItems = [];
    let tableIds = [];

    tableData.map(item => {
      tableIds.push(item[0]);
    })

    if (field.length !== 0) {
      items.map(item => {
        if (!unselectedItems.includes(item.val)) {
          if (!field.includes(item.val) && !tableIds.includes(item.val)) {
            unselectedItems.push(item);
          }
        }
      });
      return unselectedItems;
    }

    if (tableData.length !== 0) {
      items.map(item => {
        if (!tableIds.includes(item.val)) {
          unselectedItems.push(item);
        }
      });
      return unselectedItems;
    } else {
      return items;
    }
  }

  getPouOptions(consentsByPOU, pouOptions) {
    let options = [];
    let pous = Object.keys(consentsByPOU);
    pouOptions.forEach(option => {
      if (!pous.includes(option.displayString)) {
        options.push(option);
      }
    })
    return options;
  }

  renderTabForm() {
    const {
      tab,
      selectedRuleIds,
      selectedRuleNameId,
      selectedRuleName,
      selectedGroupId,
      selectedGroupName,
      applicableRulesTableData,
      propertiesTableData,
      rules,
      groups,
      showRuleNameError,
      pouOptions,
      consentsByPOU
    } = this.state;

    const ruleIdOptions = this.getRuleIdOptions();
    const getRuleNameOptions = this.getOptions(applicableRulesTableData, selectedRuleIds, rules);
    const getGroupOptions = this.getOptions(propertiesTableData, selectedGroupId, groups);
    const getPouOptions = this.getPouOptions(consentsByPOU, pouOptions);

    switch (tab) {
      case 'application':
        return (
          <div>
            <div className={styles.application_input_container}>
              <MultiSelectDropDown
                className={cn(styles.rule_dropdown, {[styles.selected]: selectedRuleIds.length !== 0})}
                placeholder={selectedRuleIds.length == 0 ? formatMessage({ id: "au.permitsLookup.placeholder.chooseRuleId"}) : formatMessage({ id: 'au.permitsLookup.count' }, { count: selectedRuleIds.length })}
                options={ruleIdOptions}
                toggleOption={this.toggleRuleId}
                disabled={ruleIdOptions.length == 0}
                selection={selectedRuleIds}
              />
              <AuDropDown
                selection={selectedRuleName}
                ref={this.ruleNameRef}
                className={styles.name_input}
                options={getRuleNameOptions}
                placeholderId="au.permitsLookup.placeholder.typeRuleName"
                selectOption={this.handleRuleNameChange}
                allowTyping={true}
                hideToggle={true}
                filterSuggestedOptions={true}
                createMode={true}
                onClear={() => this.setState({ selectedRuleName: '', showRuleNameError: false })}
                showError={showRuleNameError && this.showValidationError(rules, selectedRuleNameId)}
                error={{ errDisplayId: 'au.validation.notValid', fieldDisplayId: 'au.permitsLookup.ruleName' }}
              />
              <AuButton
                className={styles.add_button}
                displayId="au.permitsLookup.add"
                type={BUTTON_TYPE_SECONDARY}
                onClick={() => this.addToTable()}
              />
            </div>
            <div className={styles.table_container}>
              <SimpleTable>
                <Row>
                  {applicationColumnDefs.map(col => (
                    <HeaderCell
                      key={`header_${col.property}`}
                      width={col.width}
                      className={cn(col.className, styles.header_cell)}
                    >{formatMessage({ id: col.labelId })}</HeaderCell>
                  ))}
                </Row>
                {
                  applicableRulesTableData.map((rule, i) => {
                    let cells = [];
                    applicationColumnDefs.forEach((col, i) => {
                      cells.push(
                        <Cell
                          key={`row_${i}_prop_${col.property}`}
                          width={col.width}
                          className={col.className}
                        >{rule[i]}</Cell>
                      );
                    });
                    return (
                      <Row 
                        key={`row_${i}`}
                        id={`${rule[0]}`}
                        index={i}
                      >{cells}</Row>
                    );
                  })
                }
              </SimpleTable>
              {applicableRulesTableData.length == 0 && <AutoIntl displayId="au.permitsLookup.nothingToDisplay" tag="div" className={styles.empty_table} />}
            </div>
          </div>
        );
      case 'properties':
        return (
          <div className={styles.inputs_container}>
            <div className={styles.inputs}>
              <div className={styles.inputs_container_inner}>
                <AutoIntl displayId="au.permitsLookup.consentsByPOU" className={styles.text_label} tag="div" />
                <TagEditor
                  onChange={val => this.handleInputChange('consentsByPOU', val)}
                  tagOptions={getPouOptions}
                  optionsClassName={styles.namespace_options}
                  valueOptions={[{displayString: "true", val: "true"}, {displayString: "false", val: "false"}]}
                  className={styles.inputs}
                  inputClassName={styles.pou_input}
                  hintDisplayId={"au.permitsLookup.pou.hint"}
                  namePlaceholder={formatMessage({ id: 'au.permitsLookup.chooseId' })}
                  valuePlaceholder={formatMessage({ id: 'au.permitsLookup.chooseBoolean' })}
                  validationRules={{
                    fieldDisplayId: 'au.permitsLookup.boolean',
                    tagNameErrorDisplayId: 'au.permitsLookup.Pou',
                  }}
                  allowTyping={false}
                  showSubTitle={false}
                />
              </div>
              <div className={styles.inputs_container_inner}>
                <AutoIntl displayId="au.permitsLookup.tags" className={styles.text_label} tag="div" />
                <TagEditor
                  hideToggle={true}
                  onChange={val => this.handleInputChange('tags', val)}
                  className={styles.inputs}
                  inputClassName={styles.tag_input}
                  hintDisplayId={"au.permitsLookup.tags.hint"}
                  namePlaceholder={formatMessage({ id: 'au.permitsLookup.typeTagKey' })}
                  valuePlaceholder={formatMessage({ id: 'au.permitsLookup.typeTagValue' })}
                  validationRules={{tagNameMaxLength: 24, tagValueMaxLength: 40}}
                />
              </div>
              <div className={styles.inputs_container_inner}>
                <AutoIntl displayId="au.permitsLookup.groups" className={styles.text_label} tag="div" />
                <div>
                  <div className={styles.inputs_group_container}>
                    <AuDropDown
                      selection={selectedGroupName}
                      showSubTitle={true}
                      ref={this.groupNameRef}
                      className={styles.group_input}
                      options={getGroupOptions}
                      placeholderId="au.permitsLookup.typeIdOrName"
                      selectOption={this.handleGroupNameChange}
                      allowTyping={true}
                      hideToggle={true}
                      filterSuggestedOptions={true}
                      createMode={true}
                      showError={this.showValidationError(groups, selectedGroupId)}
                      error={{errDisplayId: 'au.validation.notValid', fieldDisplayId: 'au.permitsLookup.groupName'}}
                    />
                    <AuButton
                      displayId="au.permitsLookup.add"
                      type={BUTTON_TYPE_SECONDARY}
                      className={styles.add_button}
                      onClick={() => this.addToTable()}
                    />
                  </div>
                  <div className={styles.table_container}>
                    <SimpleTable>
                      <Row>
                        {propertiesColumnDefs.map(col => (
                          <HeaderCell
                            key={`header_${col.property}`}
                            width={col.width}
                            className={col.className}
                          >{formatMessage({ id: col.labelId })}</HeaderCell>
                        ))}
                      </Row>
                      {
                        propertiesTableData.map((group, i) => {
                          let cells = [];
                          propertiesColumnDefs.forEach((col, i) => {
                            cells.push(
                              <Cell
                                key={`row_${i}_prop_${col.property}`}
                                width={col.width}
                                className={col.className}
                              >{group[i]}</Cell>
                            );
                          });
                          return (
                            <Row
                              key={`row_${i}`}
                              id={`${group[0]}`}
                              index={i}
                            >{cells}</Row>
                          );
                        })
                      }
                    </SimpleTable>
                    {propertiesTableData.length == 0 && <AutoIntl displayId="au.permitsLookup.nothingToDisplay" tag="div" className={styles.empty_table} />}
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
    }
  }

  handleInputChange = this.handleInputChange.bind(this);
  handleInputChange(field, value) {
    this.setState({ [field]: value }, this.updateLookupObject(field, value));
  }

  updateLookupObject(field, value) {
    this.setState(prevState => ({
      lookup: {
        ...prevState.lookup,
        [field]: value
      }
    }));
  }

  showValidationError(options, value) {
    let displayStrings = [];

    options.map(option => {
      displayStrings.push(option.displayString);
    })

    return value.length !== 0 && !displayStrings.includes(value);
  }

  render() {
    const { tabs } = this.props;
    const { tab, topLevelMessageName, namespace, topLevelMessageNameOptions, versions, messageOptions } = this.state;

    const navTabs = tabs.map(t => ({
      labelId: `au.permitsLookup.tab.${t}`, selected: tab === t, onClick: () => this.setState({ tab: t }), className: styles.tab
    }));
    const getFtcpVersionOptions = this.getDropDownOptions(versions, namespace);
    const getTopLevelMessageNameOptions = this.getDropDownOptions(topLevelMessageNameOptions, topLevelMessageName);

    return (
      <div className={styles.container}>
        <Breadcrumbs className={styles.breadcrumbs} crumbs={[{ key: 'Permits', displayId: 'au.permitsLookup.lookupPermitsForMessage' }]} />
        <div className={styles.form_container}>
          <div className={styles.inputs_container}>
            <div className={styles.labels}>
              <AutoIntl displayId="au.permitsLookup.topLevelMessageName" className={styles.message_label} />
              <div className={cn(styles.name_label_container, {[styles.error_margin]: this.showValidationError(messageOptions, topLevelMessageName)})}>
                <AutoIntl displayId="au.permitsLookup.namespaceFtcpVersion" />
                <AutoIntl displayId="au.permitsLookup.optional" className={styles.optional} />
              </div>
            </div>
            <div>
              <AuDropDown
                options={getTopLevelMessageNameOptions}
                placeholderId="au.permitsLookup.placeholder.typeOrChoose"
                className={styles.name_dropdown}
                selectOption={val => this.handleInputChange('topLevelMessageName', val)}
                allowTyping={true}
                showClearBtn={false}
                createMode={true}
                showError={this.showValidationError(messageOptions, topLevelMessageName)}
                error={{errDisplayId: 'au.validation.notValid', fieldDisplayId: 'au.permitsLookup.topLevelMessageName'}}
                />
              <AuDropDown
                options={getFtcpVersionOptions.toReversed()}
                placeholderId="au.permitsLookup.placeholder.choose"
                className={styles.version_dropdown}
                selectOption={val => this.handleInputChange('namespace', val)}
                createMode={true}
                optionsClassName={styles.namespace_options}
                disableSort={true}
              />
            </div>
          </div>
          <Tabs className={styles.tabs} navLinks={navTabs} />
          <div className={styles.tabs_form_container}>
            { tab == 'application' && <AutoIntl className={styles.hint} displayId="au.permitsLookup.application.hint" tag="div"/>}
            {this.renderTabForm()}
          </div>
        </div>
        <div className={styles.controls}>
          <AuButton
            className={styles.lookup_button}
            type={BUTTON_TYPE_PRIMARY}
            displayId="au.permitsLookup.lookupPermits"
            onClick={() => this.lookupPermits()}
            disabled={!topLevelMessageName }
          />
          <AuButton displayId="au.cancel"
            type={BUTTON_TYPE_PLAIN}
            onClick={() => browserHistory.push(this.baseUrl.replace('/lookup') + '/rules/list')}
          />
        </div>
      </div>
    );
  }
}